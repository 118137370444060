import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import HeroImage from "../../components/global/heroImage";
import InnerLayout from "./../../components/global/innerLayout"
import parse from "html-react-parser";
import StorySubmissionNavigation from "./../../components/story-submission-section/storySubmissionNavigation"

const Page = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "storyGuidelines") {
              ... on Craft_storyGuidelines_storyGuidelines_Entry {
              seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
                id
                title
                uri
                storySubmissionBody
                storySubmissionHeroImage {
                  ... on Craft_storySubmissionHeroImage_image_BlockType {
                    image {
                      url
                      title
                      ... on Craft_websiteGeneral_Asset {
						altText
                      }
                    }
                    imageLink {
                        linkUrl
                        openInNewTab
                    }
                  }
                }
              }
            }
          }
        }
  `)

    return (
        <InnerLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>
            <div className="page-title">
                <div className="container">
                    <h2>{data.craft.entry.title}</h2>
                </div>
            </div>

            <div className="content-page">
                <div className="container-small">
                    <div className="static-page-banner">
                        <HeroImage image={data.craft.entry.storySubmissionHeroImage}/>
                    </div>
                    <div className="content-colum-row">

                        <div className="sidebar">
                            <StorySubmissionNavigation active={data.craft.entry.uri}/>
                        </div>

                        <div className="content-right">
                            {parse(data.craft.entry.storySubmissionBody)}
                            <div className="back-top"><a href="#top">Back to Top</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Page
